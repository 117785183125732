import React from "react"

const ProjectItem = ({ data, i }) => {
  return (
    <div key={i} className={"projects-item"}>
      <div className={"projects-item__title"}>
        <div className={"title-container"}>
          <h2>{data.title}</h2>
        </div>
        <p>{new Date(data.date).toLocaleDateString()}</p>
      </div>
      <div className={"projects-item__image-wrapper"}>
        <img
          loading={"lazy"}
          className={"image-wrapper__image"}
          src={data.image ?? "/assets/logo.png"}
          alt={data.title}
        />
        <div className={"image-wrapper__text"}>
          <p>{data.contentText}</p>
        </div>
      </div>
    </div>
  )
}

export default ProjectItem
