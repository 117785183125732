import React from "react"
import "../styles/projects.css"
import ProjectItem from "./ProjectItem"

const Projects = ({ data }) => {
  return (
    <div className={"projects-wrapper"}>
      {data?.map((item, i) => (
        <ProjectItem key={i} data={item} i={i} />
      ))}
    </div>
  )
}

export default Projects
