import React from "react"
import ProjektTemplate from "../templates/ProjektTemplate"
import Layout from "../Components/Layout"

const projekt = () => {
  return (
    <Layout>
      <ProjektTemplate />
    </Layout>
  )
}

export default projekt
