import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Projects from "../Components/Projects"

const ProjektTemplate = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
        edges {
          node {
            frontmatter {
              date
              title
              contentText
              image
            }
          }
        }
      }
    }
  `)

  const parsedData = data?.allMarkdownRemark?.edges?.map(
    e => e.node?.frontmatter
  )
  return (
    <div className={"projects-wrapper"}>
      <Projects data={parsedData} />
    </div>
  )
}

export default ProjektTemplate
